import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',  // 重定向:重新指向其它path,会改变网址
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: '/home/aboutView',
        name: 'aboutView',
        component: () => import('../views/AboutView.vue'),
        meta: { title: '智慧乡村综合信息数字化平台' }
      },
      {
        path: '/cunz/aboutView/mhq-detail',
        name: 'mhq-detail',
        component: () => import('../views/Cunz/detail/mhq-detail.vue'),
        meta: { title: '梅花拳' }
      },
      {
        path: '/cunz/index',
        name: 'index',
        component: () => import('../views/Cunz/index.vue'),
        meta: { title: '村庄党建' }
      },
      {
        path: '/cunz/incorrupt',
        name: 'incorrupt',
        component: () => import('../views/Cunz/incorrupt.vue'),
        meta: { title: '廉政建设' }
      },
      {
        path: '/cunz/village',
        name: 'village',
        component: () => import('../views/Cunz/village.vue'),
        meta: { title: '村史馆' }
      },
      {
        path: '/cunz/administer',
        name: 'administer',
        component: () => import('../views/Cunz/administer.vue'),
        meta: { title: '村庄治理' }
      },
      {
        path: '/cunz/family',
        name: 'family',
        component: () => import('../views/Cunz/family.vue'),
        meta: { title: '家风馆' }
      },
      {
        path: '/cunz/detail/family-detail',
        name: 'family-detail',
        component: () => import('../views/Cunz/detail/family-detail.vue'),
        meta: { title: '家风馆' }
      },
      {
        path: '/cunz/trends',
        name: 'trends',
        component: () => import('../views/Cunz/trends.vue'),
        meta: { title: '村居动态' }
      },
      {
        path: '/cunz/loudspeaker',
        name: 'loudspeaker',
        component: () => import('../views/Cunz/loudspeaker.vue'),
        meta: { title: '大喇叭' }
      },
      {
        path: '/cunz/supermarket',
        name: 'supermarket',
        component: () => import('../views/Cunz/supermarket.vue'),
        meta: { title: '积分超市' }
      },
    ]
  },
  {
    path: '/homelz',
    name: 'homelz',
    component: () => import('../views/HomeLZ.vue'),
    children: [
      {
        path: '/homelz/LZjianshe',
        name: 'LZjianshe',
        component: () => import('../views/LZjianshe.vue'),
        meta: { title: '智慧乡村综合信息数字化平台' }
      },
      {
        path: '/cunz/aboutView/mhq-detail',
        name: 'mhq-detail',
        component: () => import('../views/Cunz/detail/mhq-detail.vue'),
        meta: { title: '梅花拳' }
      },
      {
        path: '/cunzlz/index',
        name: 'index',
        component: () => import('../views/Cunz/index.vue'),
        meta: { title: '村庄党建' }
      },
      {
        path: '/cunzlz/administer',
        name: 'administer',
        component: () => import('../views/Cunz/administer.vue'),
        meta: { title: '村务公开' }
      },
      {
        path: '/cunzlz/incorrupt',
        name: 'incorrupt',
        component: () => import('../views/Cunz/incorrupt.vue'),
        meta: { title: '廉政建设' }
      },
    ]
  },
  {
    path: '/homesy',
    name: 'homesy',
    component: () => import('../views/HomeSY.vue'),
    children: [
      {
        path: '/homesy/SYdawang',
        name: 'SYdawang',
        component: () => import('../views/SYdawang.vue'),
        meta: { title: '智慧乡村综合信息数字化平台' }
      },
      {
        path: '/cunzsy/village',
        name: 'village',
        component: () => import('../views/Cunz/village.vue'),
        meta: { title: '村史馆' }
      },
      {
        path: '/cunzsy/family',
        name: 'family',
        component: () => import('../views/Cunz/family.vue'),
        meta: { title: '家风馆' }
      },
      {
        path: '/cunzsy/detail/family-detail',
        name: 'family-detail',
        component: () => import('../views/Cunz/detail/family-detail.vue'),
        meta: { title: '家风馆' }
      },
      {
        path: '/cunzsy/trends',
        name: 'trends',
        component: () => import('../views/Cunz/trends.vue'),
        meta: { title: '村居动态' }
      },
      {
        path: '/cunzsy/loudspeaker',
        name: 'loudspeaker',
        component: () => import('../views/Cunz/loudspeaker.vue'),
        meta: { title: '大喇叭' }
      },
      {
        path: '/cunzsy/supermarket',
        name: 'supermarket',
        component: () => import('../views/Cunz/supermarket.vue'),
        meta: { title: '积分超市' }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/loginSy',
    name: 'loginSY',
    component: () => import('../views/LoginSY.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/loginLz',
    name: 'loginLz',
    component: () => import('../views/LoginLz.vue'),
    meta: { title: '登录' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const whiteList = ['/login', '/loginSy', '/loginLz']; // 白名单，不需要 token 即可访问的路由路径
router.beforeEach((to, from, next) => {
  // 更新页面的标题
  document.title = to.meta.title;
  next();
  // 检查用户是否已登录
  const hasToken = localStorage.getItem('token');

  if (hasToken) {
    // 如果有 token，允许访问
    next();
  } else {
    // 没有 token
    if (whiteList.indexOf(to.path) !== -1) {
      // 如果目标路由在白名单中，允许访问
      next();
    } else {
      // 否则重定向到登录页
      next('/login');
    }
  }
});

export default router
